@font-face {
    font-family: 'Fewo Font';
    src: url('/fonts/FewoFont-Regular.eot');
    src: url('/fonts/FewoFont-Regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/FewoFont-Regular.woff2') format('woff2'),
         url('/fonts/FewoFont-Regular.woff') format('woff'),
         url('/fonts/FewoFont-Regular.ttf')  format('truetype'),
         url('/fonts/FewoFont-Regular.svg#Fewo Font') format('svg');
}

@font-face {
    font-family: 'Fewo Font Number';
    src: url('/fonts/FewoNumbersFont-Regular.woff') format('woff'),
         url('/fonts/FewoNumbersFont-Regular.ttf')  format('truetype');
}

// @font-face {
//     font-family: 'Fewo Font New';
//     src: url('/fonts/FewoFont-New-Regular.woff') format('woff'),
//          url('/fonts/FewoFont-New-Regular.ttf')  format('truetype');
// }
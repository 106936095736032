/*

 █████  ██████  ██████  ██      ██  ██████  █████  ████████ ██  ██████  ███    ██
██   ██ ██   ██ ██   ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ████   ██
███████ ██████  ██████  ██      ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██
██   ██ ██      ██      ██      ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██
██   ██ ██      ██      ███████ ██  ██████ ██   ██    ██    ██  ██████  ██   ████

*/

#root {
  height: 100%;
}

* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.App {
  min-height: 10000px;
  font-family: var(--font-family);
  color: var(--white);
  // background-color: var(--color-black);
  // height: 100vh;
  // display: flex;
  display: block;
  // padding-top: 32vh;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  color: var(--color-white);
  // background: url('/clouds.jpg');
  background-size: cover;
  background-position: center;  
  transition: all 3s ease-out;
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;

  &--active {
    padding-top: 5vh;
    overflow: visible;
    height: 100%;

    .App-logo {
      transform: scale(0.7) !important;
    }
  }  
}

.App-logo {
  // transition: all 0.6s ease-out;
  // transform: rotate(0deg) scale(1);
  cursor: pointer;
  position: absolute;
  // position: fixed;
  left: 50%;
  width: 800px;
  max-width: 80%;
  z-index: 100000;
  transform: translateX(-50%);   

  @include breakpoint(medium down) {
    max-width: 90%;
    // margin-bottom: 70%;
    top: 100px;
  }

  // @media screen and (max-height: 600px) {
  //   max-width: 50%;
  // }

  // &:hover {
    // transform: rotate(4deg) scale(1.2);
    // cursor: pointer;
  // }
}

.App-canvas {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2;
}

.App-header {
  // background-color: var(--color-darker-black);
  color: var(--color-white);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  // right: 0;
  text-align: center;
  padding: 1rem;
  // text-align: center;

  h6,
  span {
    margin: 0;
    line-height: 1;
  }


  &__inner {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    position: relative;
    z-index: 10000;    
  }
}

.App-logo {
  svg {
    width: 50px;
    height: 50px;
    margin: 0;
  }
}

.App-link {
  color: #61dafb;
}

.image-preview {
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
}

.input-field {
  padding: .85em 1em;
  margin-right: 1rem;
  font-size: 1rem;
  border: 1px solid transparent;
  height: 53px;
}

.input-field.small {
  padding: .85em 1em;
  margin-right: 1rem;
  border: none;
  width: 100px;
}

.social-icons {
  display: flex;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
}

.error__message {
  color: #db4242;
}

.span {
  &__label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    opacity: 0.5;
  }
}

.text-align {
  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
  }
}

.small-text {
  font-size: 0.9rem;
}

.no-margin {
  margin: 0;
}

.text-truncate {
  @include text-truncate();
}

// Metamask Icon
.icon__st0 {
  fill: #E2761B;
  stroke: #E2761B;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st1 {
  fill: #E4761B;
  stroke: #E4761B;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st2 {
  fill: #D7C1B3;
  stroke: #D7C1B3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st3 {
  fill: #233447;
  stroke: #233447;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st4 {
  fill: #CD6116;
  stroke: #CD6116;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st5 {
  fill: #E4751F;
  stroke: #E4751F;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st6 {
  fill: #F6851B;
  stroke: #F6851B;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st7 {
  fill: #C0AD9E;
  stroke: #C0AD9E;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st8 {
  fill: #161616;
  stroke: #161616;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon__st9 {
  fill: #763D16;
  stroke: #763D16;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.fw-content {
  opacity: 0;
  transform: translateY(500px);
  transition: all 3s ease-out;
  z-index: 1000;
  position: relative;
  padding-top: 40px;

  &--active {
    transform: translateY(0px);
    opacity: 1;
  }

  &__tile {
    text-align: center;

    img {
      width: 80%;
      margin: 0 auto;
      display: block;
      transition: all 0.3s ease-out;
      transform: scale(1) rotate(0deg);

      &:hover {
        transform: scale(1.2) rotate(6deg);
        filter: hue-rotate(90deg);
      }
    }
  }
}

body {
  background: #11ccf8;
  // overflow: hidden;
  min-height: 10000px;

  &.scroll {
    height: 4600px;
    overflow: visible;
  }

  &.lock {
    overflow: hidden;

    .main-intro-section {
      z-index: 100000;
    }

  }

  &.body-canvas {
    min-height: 100vh;
    background: #f4f4f4;
    // background-image: url("fewo-world-bg-light-cloud.webp");
  }

  &.body-faq {
    min-height: 100vh;
    background: #11ccf8 !important;
  }  

  .loading {
    font-family: "Fewo Font", sans-serif;
    color: #000;
    margin-bottom: 17%;
    font-size: 5.5rem;

    @include breakpoint(medium down) {
      font-size: 3.2rem;
      margin-top: 0;
      margin-bottom: 180px;
    }
  }

  .loading-overlay {
    background-size: cover;
    background-position: center;
    transition: all 1s ease-out;

    &.show {
      opacity: 1;
    }
    &.disable {
      opacity: 1;
      transform: translate3d(0, -200%, 0);
    }
  }
}

.standard-page {
  background-size: cover;
}

/* foreground (balloons/landscape)*/
div#parallax-bg-1 {
  position: fixed;
  width: 1200px;
  top: 1200px;
  left: 50%;
  margin-left: -600px;
  z-index: 3;
}
/* background middle layer*/
div#parallax-bg-2 {
  position: fixed;
  width: 2500px;
  top: 0;
  left: 50%;
  margin-left: -600px;
  z-index: 4;

  @include breakpoint(small down) {
    max-width: 100%;
    margin: 0;
    left: 0;      
  }
}
/* background layer */
div#parallax-bg-3 {
  position: fixed;
  width: 86%;
  // top: 2500px;
  // left: 50%;
  // margin-left: -470px;
  top: 1000px;
  left: 7%;  
  z-index: 5;

  @include breakpoint(medium down) {
    z-index: 11;
  }
}
/* background layer */
div#parallax-bg-4 {
  position: fixed;
  width: 100%;
  height: 250vh;
  top: 0;
  left: 0;
  z-index: 0;
  background-position: 0 -300px;
  background-size: cover;
  background-attachment: scroll;  
  // filter: blur(1px);

  @include breakpoint(medium down) {
    filter: none;
    top: -10px;    
  }
}

/* background layer */
div#parallax-bg-5 {
  position: fixed;
  width: 100%;
  height: 250vh;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  background-position: 0 -300px;
  background-size: cover;
  background-attachment: scroll;
  // filter: blur(1px);

  @include breakpoint(medium down) {
    filter: none;
    top: -10px;
  }  
}

/* foreground */
div#parallax-bg-3 div {
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  overflow: hidden;
}
div#bg-3-1 {
  // background: url("http://127.0.0.1:3000/fewo-car.png");
  width: 529px;
  height: 757px;
  top: 150px;
  right: -18%;
  background-size: contain;

  @include breakpoint(medium down) {
    width: 279px;
    top: -900px;
    right: -410px;
  }
}
div#bg-3-2 {
  // background: url("http://localhost:3000/paintdrop.png");
  width: 603px;
  height: 583px;
  top: 1050px;
  left: 70px;
  display: none !important;
}
div#bg-3-3 {
  // background: url("http://localhost:3000/paintdrop.png");
  width: 446px;
  height: 713px;
  top: 1800px;
  right: 140px;
  display: none !important;
}
div#ground {
  // background: url("https://greensock.com/_img/codepen/ground.png");
  width: 100%;
  height: 600px;
  top: 4000px;
  background-size: cover;
  position: relative;
  margin-top: -650px;
  z-index: 2;
  
}
/* middle layer clouds */
div#parallax-bg-2 div {
  // background: url("http://localhost:3000/clouds1.png");
  background-repeat: no-repeat;
  background-size: contain;  
  position: absolute;
  display: block;
  width: 488px;
  height: 225px;
  overflow: hidden;
  transition: all 0.6s ease-out;
  opacity: 0.9;
  -webkit-animation: bob-animation 10s infinite;
  animation: bob-animation 10s infinite;   

  @include breakpoint(medium down) {
    width: 260px;
    height: 125px;    
  }
  // transform: scale(1) rotate(0deg);
  // filter: hue-rotate(0deg) saturate(100%);
  
  // &:hover {
  //   -webkit-animation: filter-animation 8s infinite;
  //   animation: filter-animation 8s infinite;
  //   transform: scale(1.05) rotate(3deg);
  // }
}

// @keyframes filter-animation {
//   0% {
//     filter: hue-rotate(0deg) saturate(100%);
//     opacity: 0.9;
//   }
  
//   50% {
//     filter: hue-rotate(100deg) saturate(200%);
//     opacity: 1;
//   }
  
//   100% {
//     filter: hue-rotate(0deg) saturate(100%);
//     opacity: 0.9;    
//   }
// }

@keyframes bob-animation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  
  50% {
    transform: translate3d(0, -20px, 0);
  }

  // 75% {
  //   transform: translate3d(0, -10px, 0);
  // }  
  
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes scroll-animation {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  
  50% {
    transform: translate3d(-50%, -50px, 0);
  }

  // 75% {
  //   transform: translate3d(-50%, -10px, 0);
  // }  
  
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes pull-animation {
  0% {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(0, 0, 0);
  }  
  
  50% {
    transform: translate3d(0, -100px, 0);
  }

  // 75% {
  //   transform: translate3d(-50%, -10px, 0);
  // }  
  
  100% {
    transform: translate3d(0, -100px, 0);
  }
}

div#bg-2-1 {
  top: 250px;
  left: -310px;

  @include breakpoint(medium down) {
    top: 460px;
    left: -110px;
  }
}
div#bg-2-2 {
  top: 270px;
  right: -70px;


  @include breakpoint(medium down) {
    top: 290px;
    right: -130px;
  }  
}
div#bg-2-3 {
  top: 870px;
  left: -115px;

  @include breakpoint(medium down) {
    top: 870px;
    left: -25px;    
  }
}
div#bg-2-4 {
  top: 1120px;
  right: -130px;
}
div#bg-2-5 {
  top: 1620px;
  left: 140px;
}
div#bg-2-6 {
  top: 560px;
  left: 945px;
}

/*background layer clouds */
div#parallax-bg-1 div {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  display: block;
  width: 488px;
  height: 225px;
  overflow: hidden;
  -webkit-animation: bob-animation 10s infinite;
  animation: bob-animation 10s infinite;
  animation-delay: -5s;   
}
div#bg-1-1 {
  // background: url("http://localhost:3000/clouds2.png");
  top: 200px;
  right: 450px;
}
div#bg-1-2 {
  // background: url("http://localhost:3000/clouds3.png");
  top: 420px;
  left: 0px;
}
div#bg-1-3 {
  // background: url("http://localhost:3000/clouds2.png");
  top: 850px;
  right: -290px;
}
div#bg-1-4 {
  // background: url("http://localhost:3000/clouds3.png");
  top: 1350px;
  left: 200px;
}
div#bg-1-5 {
  // background: url("http://localhost:3000/clouds2.png");
  top: 1200px;
  left: -200px;
}

.paint-marker {
  top: 0;
  position: relative;
  height: 0;
  width: 100%;
}

section {
  position: relative;
  z-index: 2;
  height: 100vh;
  min-height: 100vh;
  max-width: 100% !important;
  // background: #ccc;
  &.inline-section {
    min-height: auto;
    height: auto;

    .logo-link-wrapper {
      display: block;
      margin: 0 auto;
      @include breakpoint(medium down) {
        margin: 2rem auto;
      }
    }
 
    .App-logo {
      position: relative;
      padding: 50px 0 0;

      @include breakpoint(medium down) {
        top: 0;
        padding: 20px 0 0;
      }
    }
  }
}

canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text {
  font-size: 48px;
  color: white;
  position: relative;
  // top: 25%;
  left: 100px;
  opacity: 0;

  p {
    font-family: "Fewo Font", sans-serif;
    color: #000;
  }
}

.description {
  font-size: 48px;
  color: white;
  position: relative;
  opacity: 0;

  p {
    font-family: "Fewo Font", sans-serif;
    color: #000;
  }
}

.pin-spacer {
  z-index: 10 !important;
  min-height: 100vh;
  max-width: 100% !important;

  &:first-of-type {
    z-index: 6 !important;
  }
  &:nth-of-type(2) {
    z-index: 6 !important;
  }
}

.parallax-container {
  // width: 100%;
  // height: 100%;
  // left: 0;
  // top: 0;
  position: fixed;
}

.welcome-section {
  .grid-x {
    height: 100%;
  }
}

.main-intro-section  {
  @include breakpoint(medium down) {
    z-index: 1000;
  }

  &.higher {
    z-index: 100000;
  }
}

.intro-section {
  &__welcome {
    text-align: center;
    color: #000;
    font-size: 3.2rem;
    line-height: 1.2;
    font-family: "Fewo Font", "Fewo Font Number", sans-serif;
    margin-bottom: 0;
    position: relative;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;      

    @include breakpoint(xlarge down) {
      font-size: 2.8rem;
      line-height: 1.3;
    }

    @include breakpoint(medium down) {
      font-size: 2.4rem;
      line-height: 1.1;
      max-width: 80%;
      margin: 0 auto;
      display: block;
      transform: scale(1) !important;
      margin-top: 20%;
    }

    @include breakpoint(small down) {
      margin-top: 0;
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 300%;
        background: radial-gradient(#fff 10%, rgba(255,255,255,0) 50%);
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0.4;
        transform: translate3d(-50%, -50%, 0);
        z-index: -1;

        @include breakpoint(medium down) {
          opacity: 0.8;
          width: 200%;
          height: 200%;          
        }
    }
  }
}

.paint-section-intro {
  .grid-x {  
    height: 100vh;
    // @include breakpoint(medium down) {
    // }
  }

  img {
    width: 50%;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 1;

    @include breakpoint(medium down) {
      width: 80%;
    }    
  }

  .description {
    text-align: center;
    color: #000;
    font-size: 3.2rem;
    font-family: "Fewo Font", sans-serif;
    margin-bottom: 0;
    position: relative;

    @include breakpoint(xlarge down) {
      font-size: 2.8rem;
      line-height: 1.3;
    }

    @include breakpoint(medium down) {
      font-size: 3.2rem;
      line-height: 1;
      max-width: 80%;
      margin: 0 auto 100px;
      display: block;
      transform: scale(1) !important;
      margin-top: 20%;
    }

    @include breakpoint(small down) {
      margin-top: 0;
    }

    // @include breakpoint(medium down) {    
    //   font-size: 3.2rem;
    //   line-height: 1;
    //   margin-bottom: 100px;
    // }
  }
}

.paint-section-one, .paint-section-two, .paint-section-three, .canvas-nft-section, .fewos-characters {
  .grid-container {
    position: absolute;
    top: 0;
    width: 100%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    display: flex;

    .cell {
      display: flex;
      justify-content: center;
      flex-direction: column; 

      @include breakpoint(large down) {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
      }

      @include breakpoint(small down) {
        padding-left: 1.875rem;
        padding-right: 1.875rem;        
      }
    }    
  }

  .grid-x {
    height: 100%;
  }

  h2 {
    font-family: "Fewo Font", sans-serif;
    color: #000;
  }

  p {
    font-family: "Fewo Font", sans-serif;
    color: #000;
    font-size: 2.8rem;
    line-height: 1.3;
    position: relative;

    @include breakpoint(medium down) {
      font-size: 1.8rem;
      // font-size: 2.1rem;
      line-height: 1.3;
      text-align: center;
      margin: 0;
    }

    @include breakpoint(small down) {
      padding-top: 250px;            
    }    

    &::before {
      content: "";
      display: block;
      width: 200%;
      height: 200%;
      background: radial-gradient(#fff 10%, rgba(255,255,255,0) 50%);
      position: absolute;
      top: 50%;
      left: 30%;
      opacity: 0.8;
      transform: translate3d(-50%, -50%, 0);
      z-index: -1;

      @include breakpoint(medium down) {
        width: 300%;
        height: 150%;
        // width: 100%;
        // height: 100%;
        left: 50%;
        top: 80%;
      }     
    }

    .fewo-quotation {
      display: inline-block;

      &--left {
        transform: scaleX(-1);
        position: relative;
        top: -5px;        
      }
    }    
  }
}


.canvas-nft-section {
  @include breakpoint(medium down) {
    text-align: center;
  }

  p {
    font-size: 2.3rem;

    @include breakpoint(medium down) {
      padding-top: 0;
      text-align: center;
      font-size: 1.8rem;
    }

    &::before {
      width: 300%;
      height: 100vh;
      left: 50%;
      top: 30%;
      transform: translate3d(-50%, -50%, 0);      
    }
  }

  &__note {
    @include breakpoint(medium down) {
      max-width: 90%;
      margin: 2rem auto 1rem !important;
    }
  }
}

.fewos-characters {
  padding-left: 100px !important;
  padding-right: 100px !important;
  margin-bottom: 100px !important;
  z-index: 10;

  @include breakpoint(xxlarge down) {
    margin-bottom: 200px !important;
  }

  @include breakpoint(xlarge down) {
    margin-bottom: 150px !important;
  }

  @include breakpoint(medium down) {
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: center;    
  }

  @include breakpoint(small down) {
    margin-bottom: 0 !important;
  }

  .grid-x {
    height: 100%;
    
    @include breakpoint(small down) {
      flex-flow: column-reverse;
    }
  }

  h2, p {
    text-align: left;
  }

  img {
    width: 300px;
    margin: 0 auto;
    display: block;

    @media screen and (max-height: 700px) {
      width: 200px;
    }

    @include breakpoint(medium down) {
      width: 100px;
    }
  }

  h2 {
    font-size: 4.6rem;

    @include breakpoint(medium down) {
      font-size: 4.2rem;
      text-align: center;
      margin-bottom: 0;
    }
  }

  p {
    @include breakpoint(medium down) {
      padding-top: 0;
      text-align: center;
      margin-bottom: 2rem;
    }

    &::before {
      @include breakpoint(medium down) {
        width: 100%;
        height: 100vh;
        left: 50%;
        top: 50%;
      }
    }
  }
}

.big-clouds {
  position: relative;
  z-index: 10000;
  height: auto;
  min-height: 100px;

  @include breakpoint(large down) {
    height: auto;
    // margin-top: -35%;
  }

  img {
    position: absolute;
    width: 160%;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    top: -490px;

    @include breakpoint(xlarge down) {
      top: -380px;
    }

    @include breakpoint(small down) {
      top: 0;
      position: relative;
    }    
  }
}

.ground-world {
  position: relative;
  z-index: 9999;
  height: auto;
  min-height: 100px;
  margin-top: 22%;

  @include breakpoint(xxlarge down) {
    margin-top: 19%;
  }

  @include breakpoint(xlarge down) {
    margin-top: 15%;
  }

  @include breakpoint(large down) {
    margin-top: 5%;
  }  

  @include breakpoint(medium down) {
    margin-top: -25%;
  }  

  @include breakpoint(small down) {
    margin-top: -35%;
  }

  video {  
    position: relative;
    // position: absolute;
    z-index: 1000;
    width: 100%;
    // top: -500px;

    @include breakpoint(medium down) {
      top: 150px;
    }

    @media screen and (max-width: 850px) {
      top: 0;
    }

    @include breakpoint(small down) {
      top: 0;
    }    

    // @include breakpoint(small down) {
    //   margin-top: -40%;
    // }
  }
}

.mind-map {
  position: relative;
  z-index: 10000;
  top: 0;
  // height: 200vh;
  min-height: 10px;
  margin-top: -40px;
  height: auto;

  @include breakpoint(large down) {
    top: 0;
    height: auto;    
    margin-top: -30px;
  }

  &::before {
    content: "";
    padding-top: 95%;
    position: relative;
    display: block;    
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;    
  }
}

.lore-section {
  text-align: center;
  height: auto;
  min-height: 100px;
  margin-top: -9%;
  position: relative;
  z-index: 9999; 

  @include breakpoint(xlarge down) {
    margin-top: -9%;
  }

  @include breakpoint(large down) {
    margin-top: -6%;
  }

  &::before {
    content: "";
    padding-top: calc(96% + 120px);
    display: block;

    @include breakpoint(xxlarge up) {
      padding-top: calc(96% + 360px);
    }

    @include breakpoint(xlarge up) {
      padding-top: calc(96% + 280px);
    }

    @include breakpoint(large down) {
      padding-top: calc(96% + 180px);
    }    

    // @include breakpoint(small down) {
    //   padding-top: 125%;
    // }
  }

  &__heading {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    background: #e4d002;
    height: 120px;
    z-index: 10000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    @include breakpoint(xxlarge up) {
      height: 360px;
      padding-top: 100px;
    }

    @include breakpoint(xlarge up) {
      height: 280px;
    }
 
    @include breakpoint(large down) {
      height: 180px;
      padding-top: 0;
    }

    h2 {
      font-family: "Fewo Font", sans-serif;
      text-transform: uppercase;
      color: #000;
      font-size: 6.5rem;
      margin: 0;

      @include breakpoint(medium down) {
        font-size: 3.2rem;
      }

      @include breakpoint(small down) {
        font-size: 2.8rem;
      }
    }    
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 120px; 
    left: 0;

    @include breakpoint(xxlarge up) {
      top: 360px;
    }

    @include breakpoint(xlarge up) {
      top: 280px;
    }

    @include breakpoint(large down) {
      top: 180px;
    }
    // @include breakpoint(medium down) {
    //   top: 20%;
    // }
  }
}

.App-scroll-icon {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0) scale(1);
  // width: 300px;
  // bottom: 0;
  -webkit-animation: scroll-animation 4s infinite;
  animation: scroll-animation 4s infinite;
  z-index: 100001;
  width: 250px;
  bottom: 5%;
  cursor: pointer;

  @include breakpoint(medium down) {
    width: 200px;
    bottom: auto;
    top: 60%;
  }  
}

.App-login-wrapper {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 100001;
  bottom: 8%;

  @include breakpoint(medium down) {
    width: 70%;
  }

  span {
    display: block;
    font-family: "Fewo Font", sans-serif;
    color: #000;
    font-size: 1.6rem;
    width: 60%;
    margin: 0 auto 1rem;
    line-height: 1.2;
    position: relative;

    @include breakpoint(medium down) {
      font-size: 1.4rem;
      width: 100%;
    }
    
    &::before {
      content: "";
      display: block;
      width: 200%;
      height: 0;
      background: radial-gradient(#ffffff 10%, rgba(255,255,255,0) 70%);
      position: absolute;
      aspect-ratio: 1;
      padding-top: 60%;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      border-radius: 50%;
      z-index: -1;   
    }    
  }  
}

.App-create-button {
  cursor: pointer;  
  background: #febd0b;
  border: 2px solid;
  border-radius: 0.5rem;
  color: #000 !important;
  display: inline-block;
  padding: 1rem;
  transition: all 0.3s ease-out;
  z-index: 10;
  font-family: "Fewo Font", sans-serif;
  margin: 0 auto;
  font-size: 1.8rem;
  height: auto;
  width: auto;  

  @include breakpoint(medium down) {
    // width: 200px;
    bottom: auto;
    top: 60%;
    font-size: 1.4rem;
  }   

  &:hover, &:focus {
    background: #fff;
  }
}

.coming-soon {
  font-family: "Fewo Font", sans-serif;
  font-size: 1.4rem;
  color: #000;
  background-size: cover;
  // background-image: url(http://localhost:3000/coming-soon-bg.png);
  padding: 25px 60px;
  text-transform: uppercase;
  margin-left: -25px;
  background-position: center;
}

.footer {
  height: auto;
  // margin-top: 120px;
  min-height: 10px;
  padding: 250px 0 30px;
  background: #e4d002;
  margin-bottom: 0;
  margin-top: 0;
  // margin-top: -100px;
  z-index: 100;

  @include breakpoint(medium down) {
    padding-top: 150px;
  }

  // @include breakpoint(xlarge up) {
  //   margin-top: 80px;
  // }

  // @include breakpoint(xxlarge up) {
  //   margin-top: 100px;
  // }

  // @include breakpoint(large down) {
  //   margin-top: 40px;
  // }

  // @include breakpoint(small down) {
  //   margin-top: 10px;
  // }  

  &__social {
    display: flex;
    justify-content: center;

  }
  &__social-link {
    width: 100px;
    height: 100px;
    margin: 0 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &--twitter {
      // background-image: url('http://localhost:3000/twitter.png');
    }
    &--discord {
      // background-image: url('http://localhost:3000/discord.png');
    }    
    &--youtube {
      // background-image: url('http://localhost:3000/youtube.png');
    }        
    &--instagram {
      // background-image: url('http://localhost:3000/instagram.png');
    }

    a {
      display: block;
      height: 100%;
    }
  }

  &__logo-wrapper {
    img {
      width: 400px;
      margin: 0 auto 50px;
      display : block;
      max-width: 80%;
    }    
  }

  &__copyright-row {
    font-size: 0.8rem;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    color: #000;
    text-transform: uppercase;
    font-family: "Fewo Font", sans-serif;

    &--link {
      a {
        color: #000;
        margin: 0 1rem;

        &:hover {
          color: #000;
        }
      }
    }
  }
}

.connect-wallet {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100003;
  width: 200px;
  height: 80px;

  @include breakpoint(medium down) {
    width: 120px;
    height: 60px;    
  }
  
  &__btn {
    width: 100%;
    height: 100%;
    position: absolute;
    // background-image: url(http://localhost:3000/connect-wallet-button.png);
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    z-index: 5;
    transition: all 0.3s ease-out;
    transform: translate3d(0, 0, 0);
  }

  &:hover {
    .connect-wallet__btn {
       transform: translate3d(0, -100px, 0);   
    }

    .connect-wallet__coming-soon {
      opacity: 1;
    }
  }

  &__coming-soon {
    font-family: "Fewo Font", sans-serif;
    color: #000;
    display: block;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-out;

    @include breakpoint(medium down) {
      font-size: 1rem;
    }
  }
}

.team {
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #e4d002;
  height: auto;
  min-height: 70vh;
  padding-top: 150px;

  @include breakpoint(xxlarge up) {
    padding-top: 200px;
  }

  @include breakpoint(small down) {
    padding-top: 100px;
  }

  &__member-image {
    position: relative;
    background: black;
    width: 180px;
    margin: 0 auto;
    background-size: contain;

    @include breakpoint(medium down) {
      width: 90%;
    }
    
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
    }
  }

  &__member {
    margin-bottom: 3rem;

    @include breakpoint(medium down) {
      margin-bottom: 2rem;
    }
  }

  &__member-info {
    h3 {
      margin-bottom: 0;
      font-family: "Fewo Font", sans-serif;
      margin-top: 0.5rem;
      color: #000;
      line-height: 1.1; 

      span {
        font-family: "Fewo Font", sans-serif;
        font-size: 1rem;
        text-transform: none;

      }
    }

    span {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      color: #000;
      display: block;
      font-size: 0.8rem;
      margin-top: 0.3rem;    

      @include breakpoint(medium down) {
        font-size: 0.7rem;
        margin-top: 0.3rem;
        line-height: 1.3;          
      }
    }
  }

  .grid-container {
    max-width: 65rem;

    @include breakpoint(large down) {
      max-width: 80%;
    }

    @include breakpoint(medium down) {
      max-width: 90%;
    }
  }

  h2 {
    font-family: "Fewo Font", sans-serif;
    color: #000;
    font-size: 3.8rem;
    margin-bottom: 2rem;

    @include breakpoint(medium down) {
      font-size: 2.8rem;
    } 
  }

  a {
    color: #000;

    &:hover, &:focus {
      color: #000;
    }
  }
}

.nav-buy-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100003;
  width: 200px;
  height: 80px;
  padding: 25px 30px;
  color: #000;
  font-size: 1.8rem;
  line-height: 1.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-family: "Fewo Font", sans-serif;
  transition: all 0.3s ease-out;
  transform: scale(1) rotate(0);
  display: none !important;

  @include breakpoint(medium down) {
    width: 140px;
    height: 60px;
    padding: 0;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;  
  }

  &:hover, &:focus {
    color: #000;

    @include breakpoint(large up) {
      transform: scale(1.1) rotate(-2deg);
    }
  }  
}

.buy-paint-btn {
  font-family: "Fewo Font", sans-serif;
  padding: 25px 30px;
  color: #000;
  font-size: 2.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  margin-top: 1rem;
  transition: all 0.3s ease-out;
  transform: scale(1) rotate(0);

  &:hover, &:focus {
    transform: scale(1.2) rotate(3deg);
    color: #000;
  }

  @include breakpoint(medium down) {
    display: block;
    text-align: center;
    padding: 15px 20px;
    font-size: 1.8rem;
  }
}

.see-canvas-btn {
  font-family: "Fewo Font", sans-serif;
  padding: 100px 30px;
  color: #000;
  font-size: 2.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  margin-top: 1rem;
  transition: all 0.3s ease-out;
  transform: scale(1) rotate(0);
  width: 330px;

  &:hover, &:focus {
    transform: scale(1.2) rotate(3deg);
    color: #000;
  }

  @include breakpoint(medium down) {
    display: block;
    text-align: center;
    padding: 70px 20px;
    font-size: 1.8rem;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f3f1;
  z-index: 1000002;
}

.city-section {
  position: relative;
  display: block;
  z-index: 10;
  height: auto;
  padding: 3rem 0;

  @include breakpoint(medium down) {
    padding: 5rem 0 3rem;
  }

  &--archive {
    @include breakpoint(medium down) {
      padding: 0rem 0 3rem;
    }    
  }

  &--nyc21 {
    @include breakpoint(medium down) {
      padding: 5rem 0 15rem;
    }    
  }

  &__city {
    display: block;
    background-size: 100%;
    position: relative;
    outline: none;
    z-index: 10;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &:hover {
      .city-image {
        display: none;
      }

      .city-image-hover {
        display: block;
      }      
    }

    .city-image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .city-image-hover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;      
    }

    &--coming-soon {    
      &::after {
        content: "Gallery Coming Soon";
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: "Fewo Font";
        color: #000;
        font-size: 1.8rem;
        background: radial-gradient(#d2cdcd 15%, rgba(0, 0, 0, 0) 40%);
        opacity: 0;
        transition: all 0.3s ease-out;
        transform: rotate(4deg);
        padding: 0 30%;
        line-height: 1.2;      

        @include breakpoint(medium down) {
          font-size: 1rem;
          letter-spacing: -1px;
        }
      }
    }


    &.active {
      &::after {
        opacity: 1;
      }
    }
  }

  &__city-column {
    position: relative;

    &::after {
      content: "";
      width: 70%;
      height: 70%;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      filter: blur(30px);
      left: 50%;
      transition: all 0.15s ease-out;
      transform: translate(-50%,-25%);
      z-index: 0;
    }

    &:hover {
      &::after {
        background: rgba(0, 0, 0, 0.8);        
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(4){
      .city-section__city {
        transform: translate3d(0, 10px, 0);

        @include breakpoint(medium down) {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .grid-container {
    position: relative;
  }

  h2, p {
    font-family: "Fewo Font", "Fewo Font Number", sans-serif;
    color: #000;
    position: relative;
    text-align: center;
  }

  h2 {
    font-size: 4.5rem;

    @include breakpoint(medium down) {
      font-size: 3.6rem;
    }
  }

  p {
    font-size: 2.8rem;
    line-height: 1.3; 
    text-align: center;   

    @include breakpoint(medium down) {
      font-size: 1.6rem;
      line-height: 1.2;
    }    
  }
}

.slick-track {
  display: flex;
}

.slick-slide img {
  margin: auto;
}

.slick-slide {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.slick-slide.slick-active.slick-current {
    opacity: 1;
}

ul.slick-dots {
    list-style: none;
    display: flex !important;
    margin: 0;
    justify-content: center;
    padding: 20px 0;
    position: relative;
    z-index: 100;
  
  li {
    background: #f2f2f2;
    width: 14px;
    height: 14px;
    margin: 0 10px;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
    border: 1px solid #bdbdbd;

    &:hover {
      background: #fcc733;
    }

    &.slick-active {
      background: #fcc733;
      opacity: 1;
      border-color: #000;
    }

    button {
      display: block;
      width: 100%;
      overflow: hidden;
      text-indent: -1000px;
    }
  }
}

.ranking-section {
  z-index: 100;
  height: auto;
  min-height: 0;
  position: relative;

  h2 {
    font-family: "Fewo Font", sans-serif;
    color: #000;
    font-size: 4.8rem;
    line-height: 1.3;
    position: relative;
    text-align: center;
    margin-bottom: 2rem;    
  }

  .slider-wrapper {  
    position: relative;

    &::before {
      content: "";
      padding-top: 60%;
      display: block;
    }
  }

  .slick-slider.slick-initialized {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
}

.slick-arrow.slick-next, .slick-arrow.slick-prev {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 100;
  overflow: hidden;
  text-indent: -1000px;
  width: 50px;
  height: 50px;
  border-radius: 50%;  
  background-size: cover;
  transform: scale(1);
  transition: all 0.3s ease-out;
  cursor: pointer;

  @include breakpoint(medium down) {
    top: 80%;
    z-index: 200;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.slick-arrow.slick-prev {
  left: -5%;

  @include breakpoint(medium down) {
    left: 5%;
  }
}

.slick-arrow.slick-next {
  right: -5%;

  @include breakpoint(medium down) {
    right: 5%;
  }  
}

span.original-sale-banner {
  background-size: contain;
  padding: 15px 50px;
  background-repeat: no-repeat;
  font-family: "Fewo Font", sans-serif;
  color: #000;
  font-size: 1.4rem;
  line-height: 1;
  position: relative;
  background-position: center;
  margin: 0 auto;
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: 10%;
  left: calc(50% + 130px);
  transform: translateX(-50%) rotate(-6deg);

  @include breakpoint(medium down) {
    top: 15%;    
    left: calc(50%);
    transform: translateX(-50%) rotate(-6deg);
    width: 70%;
    text-align: center;    
  }
  
  @include breakpoint(small down) {
    top: 22%;
  }
}

.canvas-note {
  font-size: 1rem !important;
  margin-top: 25rem !important;
  font-family: 'Fewo Font', sans-serif;

  @include breakpoint(small down) {
    margin-top: 10rem !important;
  }

  &--subnote {
    margin-top: 2rem !important;
    font-weight: bold;
  }

  &--subsubnote {
    font-family: sans-serif !important;
    margin-top: 3rem !important;
    font-weight: normal;    
    font-size: 0.8rem !important;

    a {
      // color: #b20000;
      color: #000000;
      text-decoration: underline;    
      text-transform: none;
      font-weight: normal;
    }
  }

}

.standard-section {
  position: relative;
  display: block;
  z-index: 10;
  height: auto;
  padding: 3rem 0;

  h1 {
    text-align: center;
    font-family: "Fewo Font";
    font-size: 4.6rem;    

    @include breakpoint(medium down) {
      font-size: 3.6rem;
    }
  }
  
  h2, p {
    color: #000;
    position: relative;
    text-align: center;
  }

  h2 {
    font-size: 4.5rem;
    font-family: 'Fewo Font', sans-serif;

    @include breakpoint(medium down) {
      font-size: 3.6rem;
    }
  }

  p {
    font-family: sans-serif;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;   

    @include breakpoint(medium down) {
      font-size: 1.2rem;
      line-height: 1.2;
    }    
  }

  li {
    font-family: sans-serif;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;   

    @include breakpoint(medium down) {
      font-size: 1.2rem;
      line-height: 1.2;
    }        
  }
}

.faq-section-heading {
  font-size: 3.6rem !important;
  font-family: "Fewo Font", sans-serif !important;
  text-align: left !important;

  @include breakpoint(medium down) {
    font-size: 2.8rem !important;
  }          

  @include breakpoint(small down) {
    font-size: 2.5rem !important;
  }              
}

.faq-qa {
  margin-bottom: 2rem;
  
  h3 {
    font-family: "Fewo Font", sans-serif;
    background: #f1d207;
    padding: 1rem;
    border-radius: 10px;
    line-height: 1;    
  }

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      color: #f14b2b;
      text-decoration: none;
    }
  }

  p, li {
    text-align: left;
  }

  p {

    @include breakpoint(small down) {
      font-size: 1rem !important;
    }                 

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.faq-qa-answer-wrapper {
  background: #fff;
  padding: 1rem;
  border-radius: 10px;

  ul {
    p {    
      font-weight: bold;
      font-size: 1.2rem;
    }

    ul {
      margin: 0 0 1rem;      
    
      li {
        font-size: 1.1rem;
        list-style: none;
      }

      ul {
        margin-left: 1rem;
      }
    }
  }
}

.canvas-tile {
  margin-bottom: 4rem;

  &__link {
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s ease-out;
    transform: translate(0,0) scale(1);
    background: #bbb;

    &:hover {
      transform: translate(0,-10px) scale(1.05);      
    }

    &::before {
      padding-top: 100%;
      display: block;
      content: "";
    }
  }

  &__thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);    

    &--portrait-true {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  
  h3 {
      padding-top: 1rem;
      display: block;
      text-align: center;
      font-family: 'Fewo Font', sans-serif;        
      color: #000;
  }

  &__number-span {
      font-family: 'Fewo Font Number', sans-serif;            
      margin-left: 1rem;
  }
}

.canvas-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 100;
  display: none;

  &.active {
    display: block;
  }
}

.canvas-modal {
  position: fixed;
  z-index: 101;
  width: auto;
  max-height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  @include breakpoint(small down) {
    width: 55%;
  }

  &.active {
    display: block;
  }  
  
  img {
    max-height: 90vh;
  }
}

.canvas-close {
  position: absolute;
  right: -40px;
  top: -40px;
  color: #fff;
  cursor: pointer;
  font-size: 2.1rem;
  font-family: 'Fewo Font', sans-serif;
  font-weight: bold;
}

.canvas-link-tiles {
  position: absolute;
  z-index: 102;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
}

.canvas-link-tile {
  &--width-2 {
    width: 50%;
  }

  &--width-3 {
    width: 33.333%;
  }  

  &--width-4 {
    width: 25%;
  }  

  a {
    display: block;
    height: 100%;
    margin-top: 0%;
    position: relative;

    &:hover {
      img {
        display: block;
      }
    }

    img {
      display: none;
      max-height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;      
    }
  }
}

.canvas-back-link {
  font-size: 1.6rem;
  font-family: 'Fewo Font', sans-serif;
  font-weight: bold;
  color: #000;
  display: block;
  position: absolute;
  top: -20px;
  left: 20px;
  z-index: 10;

  @include breakpoint(small down) {
    margin-top: 0rem;
    text-align: center;
    width: 100%;
    left: 0;
    top: -100px;
    font-size: 1.2rem;
  }

  img {
    width: 150px;
    transition: all 0.3s ease-out;
    transform: scale(1) translate3d(0,0,0) rotate(0deg);

    @include breakpoint(small down) {
      width: 120px;
    }
  }

  &:hover {
    img {
      transform: scale(1.1) translate3d(-10px, 0, 0) rotate(-2deg);
    }
  }
}

.canvas-city-banner {
  margin-bottom: 2rem;
}

.first-party-note {
  width: 200px;
  position: absolute;
  right: 0;
  bottom: 0;

  @include breakpoint(small down) {
    right: auto;
    left: 50%;
    bottom: -10rem;    
    transform: translateX(-50%);
  }
}

.fewos-page-wrapper {
  height: auto !important;
  background-size: 100%;
}

.fewos-description {
  text-align: center;
  font-size: 3.2rem;
  line-height: 1.1;
  font-family: "Fewo Font", sans-serif;
  margin: 5rem auto 0;  

  @include breakpoint(small down) {
    font-size: 1.6rem; 
    margin-top: 2rem;   
  }

  span {
    font-family: "Fewo Font Number", sans-serif;
  }
}

.fewos-root-wrapper {
  background-size: 100% !important;
  overflow-x: hidden;

  @include breakpoint(small down) {
    background-size: 200% !important;
  }
}

#fewos-page {
  padding-bottom: 10rem;
  z-index: 11;

  @include breakpoint(small down) {
    padding-bottom: 5rem;
  }
}

#fewos-page-holders {
  background-attachment: fixed;
  margin-top: -10rem;
  padding-bottom: 20rem;

  @include breakpoint(small down) {
    background-attachment: scroll;
    margin-top: -5rem;
    padding-top: 5rem;
  }

  .grid-container {
    margin-top: -20rem;
  }
}

#fewos-fall {
  margin-top: -30rem;
  min-height: 20vh;
  padding-bottom: 5rem;
  padding-top: 10rem;  
}

#fewos-wallet-section {
  margin-top: -20rem;
  padding-top: 15rem;
  padding-bottom: 50rem;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 2400px) {
    padding-bottom: 50rem;    
  }  

  @include breakpoint(small down) {
    padding-bottom: 30rem;
    overflow: hidden;
  }   
}

.fewos-sketch-wrapper {
  position: relative;
}

.fewos-however-text {
  position: relative;
  width: 60%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  // left: 50%;
  text-align: center;
  // transform: translateX(-50%);
  font-size: 3.2rem;
  line-height: 1.2;
  font-family: "Fewo Font", sans-serif;  
  // bottom: 0; 
  // top: 50%;

  @include breakpoint(small down) {
    font-size: 1.6rem;
  }
}

.fewos-pfp-wrapper {
  .cell {
    &:first-of-type {
      img {  
        transform: translateY(0px);
        animation: smallFloat 6s ease-in-out -3s infinite; 
      }    
    }
    &:nth-of-type(2) {  
      img {  
        transform: translateY(0px);
        animation: smallFloat 6s ease-in-out infinite;    
      }
    }
    &:nth-of-type(3) {
      img {  
        transform: translateY(0px);
        animation: smallFloat 6s ease-in-out -3s infinite; 
      }    
    }
    &:nth-of-type(4) {
      img {  
        transform: translateY(0px);
        animation: smallFloat 6s ease-in-out infinite;    
      }    
    }      
  }
}
.fewos-pfp-circle {
  background-size: 100%;

}

.fewos-pfp-wrapper {
  padding: 5rem 0;
  position: relative;

  @include breakpoint(small down) {
    padding-bottom: 0;
  } 

  h4 {
    font-family: "Fewo Font", sans-serif;      
    text-align: center;
    font-size: 2.2rem;   

    @include breakpoint(small down) {
      margin-bottom: 3rem;
    }
  }

  .cell {
    &:first-of-type {
      position: relative;
      top: 2.5rem;

      @include breakpoint(small down) {
        top: 0;
      }
    }
    &:nth-of-type(3) {
      position: relative;
      top: 3rem;

      @include breakpoint(small down) {
        top: 0;
      }      
    }    
  }
}

.fewos-eye-sketch-1 {
  position: absolute;
  top: 0;
  right: 3%;
  width: 120px;

  @include breakpoint(small down) {
    width: 60px;
  }
}

.fewos-eye-sketch-2 {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 100px;
  transform: scaleX(-1);  

  @include breakpoint(small down) {
    width: 60px;
  }  
}

.fewos-pfp {
  border-radius: 50%;
  overflow: hidden;
  max-width: 100%;
  padding: 10%;
  display: block;
}

.fewos-down-arrow {
  width: 50px;
  margin: 0 auto;
  display: block; 

  @include breakpoint(small down) {
    width: 30px;
  }   
}

.fewos-dna-wrapper {
  padding: 2rem 0;

  @include breakpoint(small down) {
    flex-direction: column-reverse;
  }

  p {
    font-size: 2.2rem;
    line-height: 1.1;
    font-family: "Fewo Font", sans-serif; 
    margin-bottom: 2rem;

    @include breakpoint(small down) {
      font-size: 1.6rem;
      text-align: center;
    }    

    span {
      font-family: "Fewo Font Number", sans-serif;
    }         
  }

  .cell {
    @include breakpoint(small down) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    } 
  }
}

.temp-image {
  width: 100%;
  height: auto;
  background: #CCC;
  border-radius: 50%;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.fewos-public-mint-wrapper {
  padding: 3rem 0;
  min-height: 60vh;

  .cell {  
    @include breakpoint(small down) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .fewos-public-mint {
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;    
  }  

  p {
    font-size: 2.2rem;
    line-height: 1.1;
    font-family: "Fewo Font", sans-serif; 
    margin-bottom: 1rem;

    @include breakpoint(small down) {
      font-size: 1.6rem;
      text-align: center;
    }

    span {
      font-family: "Fewo Font Number", sans-serif;
    }         
  }

  // p {
  //   font-size: 2.6rem;
  //   line-height: 1.1;
  //   font-family: "Fewo Font", sans-serif;
  //   width: 40%;
  //   margin: 0 auto 1rem;
  //   font-weight: bold;
  //   text-shadow: 2px 2px 0px #fff;

  //   span {
  //     font-family: "Fewo Font Number", sans-serif;
  //     text-shadow: 2px 2px 0px #fff;
  //   }         
  // }

  .fewos-public-mint-note {
    font-size: 1.4rem;
    width: 80%;
    margin: 0;
    text-shadow: none;
    font-weight: bold;
    padding-top: 1rem;

    @include breakpoint(small down) {
      width: 100%;
      font-size: 1.1rem !important;
    }

    ul {
      list-style: none;
      margin: 0;
  
      ul {
        margin-left: 1rem;

        li {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-weight: bold;  
          font-family: 'Fewo Font', sans-serif;        

          @include breakpoint(small down) {
            text-align: center;
          }                             
        }
      }
    }

    li {
        line-height: 0.9;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        font-weight: normal;
        font-family: 'Fewo Font', sans-serif;

        @include breakpoint(small down) {
          text-align: center;
        }                        
    }

    span.fewos-minting-on {
        font-family: 'Fewo Font' !important;
        display: block;
        font-size: 0.8rem;
    }
  }  
}

.fewos-public-mint-wrapper-reveal {
  min-height: 0;

  .fewos-holder-title {
    left: auto;
    display: block;
    margin: 0 auto;
  }
}

.fewos-flower-wrapper {
  padding: 5rem 0 10rem !important;

  @include breakpoint(small down) {
    padding-bottom: 5rem !important;
  } 
}

.fewos-full-image-large {
  width: 80vw;
  position: relative !important;
  left: 50%;
  right: 50%;
  margin-left: -40vw !important;
  margin-right: -40vw !important;  

  @include breakpoint(small down) {
    width: 100vw;
    margin-left: -50vw !important;
    margin-right: -50vw !important;      
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }  
}

.fewos-flower-note {
  transform: rotate(-5deg);
  font-size: 1.8rem !important;
}

.fewos-flower-image {
  transform: rotate(-3deg);
  border-radius: 20px;
  overflow: hidden;    
  width: 100%;
  display: block;
}

.fewos-plus-backpack {
  width: 70%;
  margin: -3rem auto;

  @include breakpoint(small down) {
    margin: 0 auto;
    display: block;
  } 
}

.fewos-sneaker-image {
  transform: rotate(3deg);
  border-radius: 20px;
  overflow: hidden;    

  @include breakpoint(small down) {
    margin-bottom: 5rem;
  }   
}

.fewos-more-info-button {
  width: 50%;
  display: block;
  transform: rotate(0deg);
  transition: all 0.3s ease-out;

  &:hover {
    transform: rotate(-3deg);
  }
}

.fewos-coming-soon {
  text-align: center; 
  padding: 3rem 0 5rem;

  p {
    font-size: 2.6rem;
    line-height: 1.1;
    font-family: "Fewo Font", sans-serif;
    span {
      font-family: "Fewo Font Number", sans-serif;
      text-shadow: 2px 2px 0px #fff;
    }         
  }    
}

.fewos-launching-soon {
  width: 80%;
  margin: 0 auto 5rem;
  display: block;
}

.fewos-dna-doodles {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .fewos-bone-sketch {
    width: 100px;
    height: auto;
    margin-bottom: 2rem;    
  }

  .fewos-dna-sketch {
    width: 50px;
    transform: rotate(25deg) scale(0.8);
    margin-right: 20%;
    margin-bottom: 2rem;
    position: relative;
    top: 20px;    
  }
}

.fewos-ways-to-get-wrapper {
  position: relative;  
}

.fewos-ways-to-get-image-wrapper {
  position: relative;  
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite;     
}

.fewos-ways-to-get {
  transform: rotate(-2deg); 
}

.fewos-its-true {
  width: 20%;
  position: absolute;
  bottom: -3rem;
  right: 5%;
  transform: rotate(-13deg);
}

.fewos-click-here {
  width: 15%;
  position: absolute;
  bottom: -3rem;
  left: 0;
}

.fewos-backpack-wrapper {
  p {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    font-size: 3.2rem !important;
    line-height: 1.1 !important;   

    @include breakpoint(small down) {
      font-size: 2.1rem !important;
      text-align: center;
    } 

    &.fewos-wallet-text {
      text-align: center;
      margin-bottom: 5rem;
      position: relative;
    }

    &.fewos-wallet-text--small {
      margin-bottom: 0;
      font-size: 2.4rem !important;  

      @include breakpoint(small down) {
        font-size: 1.6rem !important;
        text-align: center;
      }         
    }  
  

    .fewo-quotation-wrap {
      display: inline-block;
      white-space: nowrap;
      font-family: "Fewo Font", sans-serif;
    }

    .fewo-quotation {
      display: inline-block;

      &--left {
        transform: scaleX(-1);
        position: relative;
        top: -5px;        
      }
    }
  }

}

.fewos-sneaker-wrapper {
  position: relative;

  @include breakpoint(small down) {
    flex-direction: column-reverse;
  } 

  p {
    width: 95%;

    @include breakpoint(small down) {
      width: 100%;
    } 
  }
}

.fewos-paint-wrapper, .fewos-canvas-wrapper, .fewos-backpack-wrapper, .fewos-flower-wrapper, .fewos-sneaker-wrapper {
  padding: 2rem 0;

  p {
    font-size: 2.2rem;
    line-height: 1.1;
    font-family: "Fewo Font", sans-serif; 

    @include breakpoint(small down) {
      font-size: 1.6rem;
      text-align: center;
    }    

    span {
      font-family: "Fewo Font Number", sans-serif;
    }         
  }  

  .cell {
    @include breakpoint(small down) {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
  }
}

.fewos-canvas-wrapper {
  @include breakpoint(small down) {
    flex-direction: column-reverse;
  }
}

.fewos-paint {
  transform: translatey(0px);
  animation: smallFloat 6s ease-in-out -3s infinite;
}

.fewos-canvas {
  transform: translatey(0px);
  animation: smallFloat 6s ease-in-out infinite;
}

.fewos-nav-item {
  max-width: 150px;
}

.fewos-nav-items {
  display: flex;
  justify-content: space-between;
}

.fewos-wallet-image {
  width: 100%;
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite;  
}

.fewos-backpack-drawing {
  margin-top: 5rem;
}

.fewos-cloud-center-text-highlight {
  position: relative;
  
  &::before {
    content: "";
    display: block;
    width: 200%;
    height: 0;
    background: radial-gradient(#ffffff 10%, rgba(255,255,255,0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 60%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1;   
  }
}

.fewos-cloud-text-highlight {
  position: relative;
  
  &::before {
    content: "";
    display: block;
    width: 200%;
    height: 0;
    background: radial-gradient(#ffffff 10%, rgba(255,255,255,0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 90%;
    left: 40%;
    top: 30%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1;   
  }

  button {
    display: inline-block;
    font-family: "Fewo Font", sans-serif;
    color: #000;
    background: #fff;
    padding: 0.2rem 0.4rem;
    border: 3px solid #000;    
    transition: all 0.3s ease-out;
    transform: scale(1);
    cursor: pointer;

    &:hover {
      background: #eee;
      transform: scale(1.05);
    }
  }
}

.fewos-grass-center-text-highlight {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 200%;
    height: 0;
    background: radial-gradient(#bdf0c3 10%, rgba(255,255,255,0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1;

    @include breakpoint(small down) {
      padding-top: 120%;
      left: 50%;
      top: 50%;
    } 
  }
}

.fewos-grass-text-highlight {
  position: relative;
  
  &::before {
    content: "";
    display: block;
    width: 220%;
    height: 0;
    background: radial-gradient(#bdf0c3 10%, rgba(255,255,255,0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 100%;
    left: 30%;
    top: 30%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1; 

    @include breakpoint(small down) {
      padding-top: 120%;
      left: 50%;
      top: 50%;
    }         
  }
}

.fewos-grass-center-logo-highlight {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 200%;
    height: 0;
    background: radial-gradient(#bdf0c3 10%, rgba(255,255,255,0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 200%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1;

    @include breakpoint(small down) {
      padding-top: 200%;
      left: 50%;
      top: 50%;
    } 
  }
}

.fewos-holder-title {
  width: 70%;
  margin-bottom: 1rem;
  position: relative;
  left: -10%;
  transform: rotate(-7deg);

  @include breakpoint(small down) {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;    
  }  

  &--large {
    width: 85%;
    transform: rotate(-3deg);      
  }

  &--full {
    width: 100%;    
  }
}

.fewos-holder-subtitle {
  font-size: 2.5rem;
  position: relative;
  top: -15px;

  @include breakpoint(small down) {
    font-size: 1.8rem;
  }  
}

.fewos-down-paper-rip {
  width: 100vw;
  max-width: none;
  position: relative;
  // top: -15.9rem;
  top: -17vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @include breakpoint(small down) {
    width: 200vw;
    top: -16.6rem;
  }
}

@keyframes float {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-50px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

@keyframes smallFloat {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-25px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

.fewos-nav-icon {
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
  
  &:hover {
    transform: scale(1.05);
  }
}

.fewos-text-description {
  padding: 3rem 0 6rem;

  @include breakpoint(small down) {
    padding: 3rem 0 3rem;
  }

  .cell {
    position: relative;    
  }
}

.fewos-coming-soon-overlay {
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -5rem;
  transform: scale(1) rotate(0);
  transition: all 0.3s ease-out;

  &:hover {
    transform: scale(1.1) rotate(-2deg);
  }
  // position: absolute;
  // width: 66%;
  // top: 30%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  @include breakpoint(small down) {
    margin-top: -3rem;
  }
}

.fewos-presale-mint-note {
  font-size: 1.4rem !important;
  font-weight: bold;
  margin: 0;

  @include breakpoint(small down) {
    font-size: 1.1rem !important;
  }
}

.fewos-adidas-text {
  font-size: 3.2rem !important;

  @include breakpoint(small down) {
    font-size: 2.1rem !important;
  }   

  &--small {
    font-size: 1.8rem !important;

    @include breakpoint(small down) {
      font-size: 1.6rem !important;
    } 
  }
}

.fewos-transformation-sketch {
  overflow: hidden;
  border-radius: 20px;
  transform: rotate(3deg);
  width: 100%;
  display: block;
}

.fewos-more-coming-soon, .fewos-dressing-room {
  text-align: center;
  padding-bottom: 5rem;

  h3 {
    margin-left: auto;
    margin-right: auto;
    font-size: 3.2rem !important;
    line-height: 1.1 !important;
    font-family: "Fewo Font", sans-serif;
    margin-bottom: 1rem;    
  }

  h4 {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem !important;
    line-height: 1.1 !important;
    font-family: "Fewo Font", sans-serif;
    margin-bottom: 1rem;
    margin-top: 6rem;    

    span {
      font-family: "Fewo Font Number", sans-serif;
    }
  }

  .fewos-flower-types {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;      

    @include breakpoint(small down) {
      gap: 1rem;
    }
  }

  .fewos-holder-title {
    width: 80%;
    left: 0;
    margin-top: 5rem;
    margin-bottom: 3rem;    
  }

  .fewos-flowers-know {
    margin-bottom: 3rem;
  }

  .fewos-flower-exchange-text {
    p {
      margin-bottom: 2rem;
    }
  }

  .fewo-flower-type {
    width: calc(20% - 2rem);
    position: relative;

    @include breakpoint(small down) {
      width: calc(30% - 1rem);
    }

    img {
      overflow: hidden;
      border-radius: 1rem;
      margin-bottom: 1rem;
    } 

    span {
      display: block;
      font-family: "Fewo Font", sans-serif;
      line-height: 1;

      @include breakpoint(small down) {
        font-size: 1rem;
      }

      &.fewos-grass-text-highlight {
        &::before {
          width: 140%;
          left: 50%;
        }
      }
    } 
  }  

  p, a {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.1rem !important;
    line-height: 1.1 !important;   
    font-family: "Fewo Font", sans-serif;

    @include breakpoint(small down) {
      font-size: 1.6rem !important;
      text-align: center;
    }    
  }

  p {
    span {
      font-family: "Fewo Font Number", sans-serif;
    }
  }

  a {
    color: #000;
    background: #febd0b;
    padding: 0.2rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease-out;
    border: 2px solid;
    transform: scale(1);
    display: inline-block;
    padding: 1rem;
    margin-bottom: 2rem;    
    position: relative;
    z-index: 10;
    font-weight: bold;

    &:hover {
      background: lighten(#febd0b, 10%);
      transform: scale(1.05);
    }

    @include breakpoint(small down) {
      width: 80%;
    }
  }
}

.fewos-flowers-legend {
  background: #fff;
  font-family: "Fewo Font", sans-serif;
  list-style: none;
  padding: 2rem 1rem;
  border-radius: 1rem;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.1;

  @include breakpoint(small down) {
    font-size: 0.9rem;
  }

  span {
    font-family: 'Fewo Font Number', sans-serif;    
  }
}

.fewos-flower-mint-note {
  font-size: 1.4rem !important;
  width: 100%;
  margin: 0;
  text-shadow: none;
  font-weight: bold;

  @include breakpoint(small down) {
    width: 100%;
    font-size: 1.1rem !important;
  }
}

.dont-wrap {
  display: inline-block;
  font-family: 'Fewo Font', sans-serif !important;
}

.fewos-new-info {
  display: flex;
  align-items: center;
  font-family: 'Fewo Font', sans-serif;
  margin-top: 0.5rem;

  @include breakpoint(small down) {
    justify-content: center;
  }

  a {
    font-family: 'Fewo Font', sans-serif;
    color: #000;
    line-height: 0.9;
    display: inline-block;
    font-size: 1.1rem;
    text-decoration: underline;    
  }

  img {
    width: 35%;
    margin-right: 5%;    
  }  

  .date-number {
    font-family: "Fewo Font Number", sans-serif;
  }

  &--centered {
    justify-content: center;
    text-align: center;
  }

  span {
    @include breakpoint(small down) {
      text-align: center;
    }    
  }
}

#fewos-dressing-room-section {
  margin-top: -50rem;
  padding-top: 50rem;
  padding-bottom: 10rem;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover !important;
  overflow: hidden;

  @media screen and (max-width: 2400px) {
    margin-top: -50rem;
    padding-top: 50rem;    
  }

  @include breakpoint(small down) {
    margin-top: -30rem;
    padding-top: 35rem;
    padding-bottom: 5rem;
    overflow: hidden;
    background-position: center;
  }   
}

.fewos-dressing-room {
  h1, h2, h3, h4, h5, p, a {
    color: #fff !important;
  }

  p {
    margin-bottom: 2rem;
  }
}

.fewos-wood-text-highlight {
  position: relative;
  
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    background: radial-gradient(#261a0d 10%, rgba(255, 255, 255, 0) 70%);
    position: absolute;
    aspect-ratio: 1;
    padding-top: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;

    @include breakpoint(small down) {
      padding-top: 50%;
      left: 50%;
      top: 50%;
    }         
  }
}

.fewos-new-info-click {
  cursor: pointer;
}

.fewos-flower-calculator-image {
  display: block;
  position: absolute;
  bottom: 10vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 26%; 
  z-index: 8;  

  @include breakpoint(medium down) {
    max-width: 55%;
    bottom: 0;
  }  

  @include breakpoint(small down) {
    max-width: 80%;
    bottom: 0;
  }  
}

.fewos-countdown-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;  
  top: 0;
  left: 0;
}

.fewos-countdown-wrapper {
  aspect-ratio: 2/1;
  margin-bottom: 8rem;
  position: relative;
}

.fewos-countdown {
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 93%;
  z-index: 11;
}

.fewos-countdown-column {
  width: 25%;
  padding: 0 1rem;
  text-align: center;

  @include breakpoint(small down) {
    // padding-bottom: 2rem;
    padding: 0 0.25rem;
  }

  span {
    display: block;
    font-family: "Fewo Font", sans-serif;
    font-size: 2.8rem;

    @include breakpoint(medium down) {
      font-size: 1.3rem;
    }
  }

  .fewos-countdown-number {
    background-size: contain;
    aspect-ratio: 3/2;
    background: transparent !important;
    background-repeat: no-repeat;
    background-position: center;
    font-family: "Fewo Font Number", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.6rem;
    margin-bottom: 1rem;

    @include breakpoint(medium down) {
      font-size: 2.4rem;
      margin-bottom: 0.5rem;
    }    

    @include breakpoint(small down) {
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }        
  }
}

.fewos-faq-link { 
  h3 {
    text-align: center;
    font-size: 3.6rem;
    color: #fff;
    font-family: "Fewo Font";

    @include breakpoint(medium down) {
      font-size: 2.4rem;
    }    

    @include breakpoint(small down) {
      font-size: 1.8rem;
    }            
  }

  .button {
    display: inline-block;
    line-height: 1;
    height: auto;
    padding: 1rem;
    font-size: 1.8rem;
    font-family: "Fewo Font", sans-serif;
    border-radius: 10px;
    margin: 2rem auto 5rem;    
    transform: scale(1);
    transition: all 0.3s ease-out;
    background: #fff;
    color: #000;

    &:hover {
      background: #fff;
      color: #000;
      transform: scale(1.1);
    }

    @include breakpoint(small down) {
      font-size: 1.4rem;
    }                
  }
}

.grid-x.grid-x.grid-padding-x.align-center.align-middle.fewos-faq-link {
    text-align: center;
}

.fewos-mint-schedule {
  h2 {
    text-align: center;
    font-family: "Fewo Font", sans-serif;
    font-size: 3.6rem; 

    @include breakpoint(small down) {
      font-size: 2.1rem;
    }                  
  }

  ul {
    margin-left: 0;

    li {
      list-style: none;
      text-align: center;
      margin-bottom: 2rem;

      &>span {
        font-size: 2.2rem;          
        color: #f61f1f;
        display: inline-block;
        background: #fff;
        padding: 0.2rem 0.5rem;
        line-height: 1.1;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

        @include breakpoint(small down) {
          font-size: 1.6rem;
          max-width: 65%;
          margin-left: auto;
          margin-right: auto;
          line-height: 1.2;
          padding: 0.2rem;
        }
      }

      span, p {
        font-family: "Fewo Font", sans-serif;

        span {
          font-family: "Fewo Font Number", sans-serif;
        }
      }

      &:nth-of-type(2n) {
        &>span {
          transform: rotate(2deg);          
        }
      }

      &:nth-of-type(2n+1) {
        &>span {
          transform: rotate(-3deg);          
        }       
      }

      p {
        color: #444;

        @include breakpoint(small down) {
          font-size: 1rem;
          max-width: 76%;
          margin-left: auto;
          margin-right: auto;
        }                          
      }
    }
  }
}

.fewo-mint-note {
  text-align: center;
  font-size: 1.3rem;
  color: #444;
  font-family: "Fewo Font", sans-serif; 
  @include breakpoint(small down) {
    line-height: 1.2;
    font-size: 1.1rem;
  }                  

  span {
    font-family: "Fewo Font Number", sans-serif;
  }   

}

.fewos-mint-link-home {
  width: 300px;
  margin: 0 auto 2rem;
  display: block;
  transition: all 0.3s ease-out;
  transform: translateX(-50%) scale(1) rotate(-2deg) !important;
  animation: none !important;

  &:hover {
    transform: translateX(-50%) scale(1.1) rotate(-5deg) !important;
  }  
}

.fewos-mint-link {
  width: 80%;
  margin: 0 auto 2rem;
  display: block;
  transition: all 0.3s ease-out;
  transform: scale(1) rotate(-2deg);

  &:hover {
    transform: scale(1.1) rotate(-5deg);
  }
}

.date-number {
  font-family: "Fewo Font Number", sans-serif;
}

.fewos-inline-section {
  text-align: center;
  margin-bottom: 25rem;
  z-index: 100;
  height: auto;

  @include breakpoint(small down) {
    margin-bottom: 5rem;
  }

  .fewos-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .fewos-full-image-large {
    p {
      position: relative;    

      &:before {
        content: "";
        display: block;
        width: 140%;
        height: 200%;
        background: radial-gradient(#fff 10%, rgba(255, 255, 255, 0) 50%);
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0.8;
        transform: translate3d(-50%, -50%, 0);
        z-index: -1;      
      }
    }
  }

  .grid-container {
    margin: 0 auto;
  }

  .cell {
    margin-left: auto;
    margin-right: auto;
  }

  h1, h2, h3, h4, h5, p, span {
    color: #000;
  }

  p {
    text-align: center;
    font-family: "Fewo Font", sans-serif;
    color: #000 !important;
    font-size: 2.8rem;
    line-height: 1.3;
    position: relative;    

    &:before {
      content: "";
      display: block;
      width: 140%;
      height: 200%;
      background: radial-gradient(#fff 10%, rgba(255, 255, 255, 0) 50%);
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0.8;
      transform: translate3d(-50%, -50%, 0);
      z-index: -1;      
    }

    @include breakpoint(small down) {
      font-size: 1.4rem;
    }

    span {
      font-family: "Fewo Font Number", sans-serif;
    }
  }

  .button {
    background: #febd0b;
    border: 2px solid;
    border-radius: 0.5rem;
    color: #000 !important;
    display: inline-block;
    margin-bottom: 2rem;
    padding: 1rem;
    position: relative;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .3s ease-out;
    z-index: 10;  
    font-family: "Fewo Font", sans-serif;
    margin: 0 auto;  
    font-size: 1.8rem;
    height: auto;

    &:hover {
      background: #fff;
    }
  }
}
.App-nav {
    position: absolute;
    width: 100%;
    z-index: 1000001;
    top: 10%; 

    @include breakpoint(medium down) {
        left: 0;
        top: 16%;
    }

    &--desktop {
        display: block;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    &--mobile {
        display: none;

        @include breakpoint(medium down) {
            height: 100vh;
            top: 0px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            background: tan;
            position: fixed;
            transform: translate3d(0, -100vh, 0) !important;
            transition: all 0.3s ease-out;
            padding-top: 30px;
            // background-image: url('http://localhost:3000/mobile-nav-bg.jpg');
            background-size: cover;
            background-position: left top;
        }

        &--active {
            @include breakpoint(medium down) {
                display: flex;
                transform: translate3d(0, 0, 0) !important;
            }
        }

        .App-nav__list {
            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        .App-logo-mobile {
            @include breakpoint(medium down) {
                width: 70%;
                margin: 0 auto;
                display: block;
            }
        }

    }    

    ul {
        display: flex;
        text-align: center;
        justify-content: center;

        @include breakpoint(medium down) {
            display: flex;
            margin: 0 auto;
            flex-wrap: wrap;
        }          

        li {
            list-style: none;
            margin: 10px;
            font-size: 1.5rem;
            font-family: "Fewo Font";
            padding: 25px 30px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @include breakpoint(medium down) {
                width: 30%;
                // padding: 50px;
            }

            &:nth-of-type(1), &:nth-of-type(6) {
                // background-image: url(http://127.0.0.1:3000/smudge-bg--purple.png);

                @include breakpoint(medium down) {
                    background-size: 70%;
                }
            }

            &:nth-of-type(2) {
                // background-image: url(http://127.0.0.1:3000/smudge-bg--yellow.png);
                background-size: 100%;

                @include breakpoint(medium down) {
                    background-size: 70%;
                }                        
            }

            &:nth-of-type(3), &:nth-of-type(7) {
                // background-image: url(http://127.0.0.1:3000/smudge-bg--green.png);
                background-size: 100%;

                @include breakpoint(medium down) {
                    background-size: 70%;
                }                        
            }  

            &:nth-of-type(4), &:nth-of-type(8) {
                // background-image: url(http://127.0.0.1:3000/smudge-bg--orange.png);
                background-size: 100%;  

                @include breakpoint(medium down) {
                    background-size: 70%;
                }                      
            } 

            &:nth-of-type(5) {
                // background-image: url(http://127.0.0.1:3000/smudge-bg--red.png);
                background-size: 100%;        

                @include breakpoint(medium down) {
                    background-size: 70%;
                }                
            }            
        }

    }

    &__list-item {
        transition: all 0.3s ease-out;        
        display: flex;
        justify-content: center;
        flex-direction: column;
    
        a, button {
            font-family: 'Fewo Font';
            color: #fff;
            cursor: pointer;
            padding: 30px;

            @include breakpoint(large down) {
                padding: 20px;
            }

            &:hover, &:focus {
                cursor: pointer;
                color: #fff;
            }            
        } 

        span.no-span {
            font-size: 1.4rem;
            line-height: 0.2;
            top: -4px;

            &::before {
                content: "";
                width: 100%;
                height: 1px;
                background: #fff;
                display: block;
                transform: rotate(45deg);
                position: relative;
                top: 25px;
                left: 0px;
            }

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background: #fff;
                display: block;
                transform: rotate(-35deg);
                position: relative;
                top: 5px;
                left: -20px;              
            }
        }
    }
}


// Nav rotations
nav.App-nav ul li {
    &:nth-of-type(1) {
        transform: rotate(-10deg);

        @include breakpoint(medium down) {
            transform: rotate(-5deg);
        }

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(-12deg);
            }

        }
    }

    &:nth-of-type(2) {
        transform: rotate(12deg) translate(0, 30px);

        @include breakpoint(medium down) {
            transform: rotate(5deg);
        }

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(14deg) translate(0, 30px);
            }
        }    
    }

    &:nth-of-type(3) {
        transform: rotate(-20deg);

        @include breakpoint(medium down) {
            transform: rotate(-5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(-22deg);
            }
        }  

    }

    &:nth-of-type(4) {
        transform: rotate(13deg) translate(20px, 50px);

        @include breakpoint(medium down) {
            transform: rotate(5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(15deg) translate(20px, 40px);
            }
        }        
    }

    &:nth-of-type(5) {
        transform: rotate(-12deg);

        @include breakpoint(medium down) {
            transform: rotate(-5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(-14deg);
            }
        }     
    }

    &:nth-of-type(6) {
        transform: rotate(14deg);

        @include breakpoint(medium down) {
            transform: rotate(5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(16deg);
            }
        }     
    }

    &:nth-of-type(7) {
        transform: rotate(-13deg);

        @include breakpoint(medium down) {
            transform: rotate(-5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(-15deg);
            }
        }     
    }

    &:nth-of-type(8) {
        transform: rotate(14deg);

        @include breakpoint(medium down) {
            transform: rotate(5deg);
        }          

        &:hover {
            @include breakpoint(large up) {   
                transform: scale(1.2) rotate(16deg);
            }
        }     
    }
}

.App-menu {
    display: none;
    border: 2px solid #000;
    box-shadow: 0 0 0px 2px #fff;
    // background-image: linear-gradient(180deg, #fffed9 0%, #fff738 50%, #fff738 51%, #e38618 100%);
    // background-size: 100% 200%;
    // background-position: 0 0;
    background-position: center;
    // background-image: url('http://127.0.0.1:3000/menu-button-texture-yellow.jpg');
    background-repeat: no-repeat;
    transition: all 0.3s ease-out;
    overflow: hidden;

    @include breakpoint(medium down) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 50px;
        height: 50px;
        top: 10px;
        left: 10px;
        border-radius: 50%;
        z-index: 1000002;
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     mix-blend-mode: multiply;
    //     background-image: url('http://127.0.0.1:3000/menu-button-texture.jpg');
    //     background-size: cover;
    //     z-index: 1;

    // }

    .close-icon {
        @include breakpoint(medium down) {
            opacity: 0;
        }
    }

    .menu-icon {
        @include breakpoint(medium down) {
            opacity: 1;
        }
    }

    &--active {
        // background-position: 0 100%;

        .close-icon {
            @include breakpoint(medium down) {
                opacity: 1 !important;
            }
        }

        .menu-icon {
            @include breakpoint(medium down) {
                opacity: 0 !important;
            }
        }        
    }

    img {
        display: none;

        @include breakpoint(medium down) {  
            display: block;      
            max-width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-out;
        }
    }

}

.header-social-links {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    z-index: 100;

    @include breakpoint(medium down) {
        top: 46%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        right: auto;
        justify-content: center;        
    }

    @include breakpoint(small down) {
        top: 38%;
    }

    .header-social-link {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        box-shadow: -1px 5px 5px -2px  rgba(0, 0, 0, 0.6);

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #f3f4f6;
            z-index: -1;
        }

        &--twitter {
          // background-image: url('http://localhost:3000/twitter.png');
          background-size: 80%;
        }
        &--discord {
          // background-image: url('http://localhost:3000/discord.png');
          background-size: 80%;
        }    
        &--youtube {
          // background-image: url('http://localhost:3000/youtube.png');
          background-size: 80%;
        }        
        &--instagram {
          // background-image: url('http://localhost:3000/instagram.png');
          background-size: 80%;
        }

        a {
          display: block;
          height: 100%;
        }    
    }    
}